import axios from "axios";
import jwtDecode from "jwt-decode";
import Router from "next/router";
import { z } from "zod";

import { useJobFilterStore } from "src/components/job/JobListFilter/useJobFilters";
import { useAuthStore } from "src/context/authStore";
import * as fetchers from "src/services/fetchers";
import type { MSPartnerLoginResponse } from "src/services/models/responses";

export const logout = () => {
  // if someone is stuck at refreshing MS tokens,
  // make logging out fix the issue at least
  localStorage.setItem("refreshingTokens", "false");

  useAuthStore.getState().reset();
  useJobFilterStore.getState().dispatch({ kind: "reset" });
  Router.push("/");
};

const ClaimCodec = z.object({
  username: z.string(),
  is_admin: z.boolean(),
  partner_id: z.string(),
  permissions: z.array(z.string()),
});

export interface LoginParams {
  credentials: {
    username: string;
    password: string;
  };
}

export const login = async ({ credentials }: LoginParams) => {
  const marketplaceApiData = await fetchers.loginToMarketplaceAPI(axios, credentials);

  let msData: MSPartnerLoginResponse | undefined = undefined;

  msData = await fetchers.loginToMS(axios, {
    emailAddress: credentials.username,
    password: credentials.password,
  });

  if (marketplaceApiData?.access_token) {
    try {
      const payload = jwtDecode(marketplaceApiData.access_token);

      const claims = ClaimCodec.parse(payload);

      useAuthStore.setState({
        username: claims.username,
        isAuthenticated: true,
        isAdmin: claims.is_admin,
        accessToken: marketplaceApiData.access_token,
        msAccessToken: msData?.accessToken,
        msRefreshToken: msData?.refreshToken,
        partnerId: claims.partner_id,
        permissions: claims.permissions,
      });
    } catch (e) {
      console.log(e);
    }
  }
};
