/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { CommsClient } from './CommsClient';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AC42Variables } from './models/AC42Variables';
export type { AC43Variables } from './models/AC43Variables';
export type { Address } from './models/Address';
export type { BaseTemplatePreviewRequest } from './models/BaseTemplatePreviewRequest';
export type { BaseTemplatePreviewResponse } from './models/BaseTemplatePreviewResponse';
export type { BaseTemplateResponse } from './models/BaseTemplateResponse';
export type { BaseTemplateSchemaResponse } from './models/BaseTemplateSchemaResponse';
export type { BH14Variables } from './models/BH14Variables';
export type { BrandData } from './models/BrandData';
export type { BrandResponse } from './models/BrandResponse';
export type { BrandTheme } from './models/BrandTheme';
export type { CA19Variables } from './models/CA19Variables';
export type { CE002Variables } from './models/CE002Variables';
export type { CommCode } from './models/CommCode';
export type { CP03Variables } from './models/CP03Variables';
export type { CreateBaseTemplateRequest } from './models/CreateBaseTemplateRequest';
export type { CreateBrandRequest } from './models/CreateBrandRequest';
export type { CreateHTMLTemplateRequest } from './models/CreateHTMLTemplateRequest';
export type { CreateSMSTemplateRequest } from './models/CreateSMSTemplateRequest';
export type { FR01Variables } from './models/FR01Variables';
export type { HealthResponse } from './models/HealthResponse';
export type { HTMLTemplatePreviewRequest } from './models/HTMLTemplatePreviewRequest';
export type { HTMLTemplatePreviewResponse } from './models/HTMLTemplatePreviewResponse';
export type { HTMLTemplateResponse } from './models/HTMLTemplateResponse';
export type { HTTPValidationError } from './models/HTTPValidationError';
export type { JobCost } from './models/JobCost';
export type { K2SOVariables } from './models/K2SOVariables';
export type { LiveForProductRequest } from './models/LiveForProductRequest';
export type { MA21Variables } from './models/MA21Variables';
export type { NS42Variables } from './models/NS42Variables';
export type { PJ003Variables } from './models/PJ003Variables';
export type { PJ004Variables } from './models/PJ004Variables';
export type { PLPMVariables } from './models/PLPMVariables';
export type { PLRMVariables } from './models/PLRMVariables';
export type { RD22Variables } from './models/RD22Variables';
export type { RE25Variables } from './models/RE25Variables';
export type { SendTestEmailConfig } from './models/SendTestEmailConfig';
export type { SMSMessagePreviewRequest } from './models/SMSMessagePreviewRequest';
export type { SMSTemplatePreviewResponse } from './models/SMSTemplatePreviewResponse';
export type { SMSTemplateResponse } from './models/SMSTemplateResponse';
export type { TemplateSchemaResponse } from './models/TemplateSchemaResponse';
export type { TimeSlot } from './models/TimeSlot';
export type { Tradesperson } from './models/Tradesperson';
export type { Transport } from './models/Transport';
export type { UpdateBaseTemplateRequest } from './models/UpdateBaseTemplateRequest';
export type { UpdateBrandRequest } from './models/UpdateBrandRequest';
export type { UpdateTemplateRequest } from './models/UpdateTemplateRequest';
export type { ValidationError } from './models/ValidationError';

export { BaseTemplatesService } from './services/BaseTemplatesService';
export { BrandConfigService } from './services/BrandConfigService';
export { CommSchemaService } from './services/CommSchemaService';
export { DefaultService } from './services/DefaultService';
export { HealthService } from './services/HealthService';
export { TemplatePreviewService } from './services/TemplatePreviewService';
export { TemplatesService } from './services/TemplatesService';
