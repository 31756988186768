import { useMemo } from "react";

import { logout } from "src/utils/auth";

import { useAuthStore } from "../authStore";
import { createAxios } from "./createAxios";
import type { AuthContext } from "./types";

export const useCreateAxios = () => {
  const axiosWithAuth = useMemo(() => {
    return createAxios({
      getAuthContext: () => {
        const state = useAuthStore.getState();
        return {
          marketplaceApiAccessToken: state.accessToken,
          msAccessToken: state.msAccessToken,
          msRefreshToken: state.msRefreshToken,
        } satisfies AuthContext;
      },
      updateAuthState: useAuthStore.setState,
      logout,
    });
  }, []);

  return axiosWithAuth;
};
