/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTemplateResponse } from '../models/BaseTemplateResponse';
import type { CreateBaseTemplateRequest } from '../models/CreateBaseTemplateRequest';
import type { UpdateBaseTemplateRequest } from '../models/UpdateBaseTemplateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BaseTemplatesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Default Base Template
     * Get a default base template
     * @returns BaseTemplateResponse Successful Response
     * @throws ApiError
     */
    public getDefaultBaseTemplateApiV1BaseTemplatesDefaultGet(): CancelablePromise<BaseTemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/base_templates/default',
        });
    }
    /**
     * Get Base Template
     * Get a base template by ID
     * @returns BaseTemplateResponse Successful Response
     * @throws ApiError
     */
    public getBaseTemplateApiV1BaseTemplatesIdGet({
        id,
    }: {
        id: string,
    }): CancelablePromise<BaseTemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/base_templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Base Template
     * Delete a base template by ID
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteBaseTemplateApiV1BaseTemplatesIdDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/base_templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Template
     * Update a template
     * @returns BaseTemplateResponse Successful Response
     * @throws ApiError
     */
    public updateTemplateApiV1BaseTemplatesIdPatch({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateBaseTemplateRequest,
    }): CancelablePromise<BaseTemplateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/base_templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Template
     * @returns BaseTemplateResponse Successful Response
     * @throws ApiError
     */
    public listTemplateApiV1BaseTemplatesGet({
        partnerIds,
        brandIds,
        limit,
        offset,
    }: {
        partnerIds?: Array<string>,
        brandIds?: Array<string>,
        limit?: number,
        offset?: number,
    }): CancelablePromise<Array<BaseTemplateResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/base_templates/',
            query: {
                'partner_ids': partnerIds,
                'brand_ids': brandIds,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Base Template
     * Create a new template
     * @returns BaseTemplateResponse Successful Response
     * @throws ApiError
     */
    public createBaseTemplateApiV1BaseTemplatesPost({
        requestBody,
    }: {
        requestBody: CreateBaseTemplateRequest,
    }): CancelablePromise<BaseTemplateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/base_templates/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
