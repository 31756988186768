/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTemplatePreviewRequest } from '../models/BaseTemplatePreviewRequest';
import type { BaseTemplatePreviewResponse } from '../models/BaseTemplatePreviewResponse';
import type { HTMLTemplatePreviewRequest } from '../models/HTMLTemplatePreviewRequest';
import type { HTMLTemplatePreviewResponse } from '../models/HTMLTemplatePreviewResponse';
import type { SMSMessagePreviewRequest } from '../models/SMSMessagePreviewRequest';
import type { SMSTemplatePreviewResponse } from '../models/SMSTemplatePreviewResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TemplatePreviewService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Preview Email
     * Preview an email template with given data and theme.
     * @returns HTMLTemplatePreviewResponse Successful Response
     * @throws ApiError
     */
    public previewEmailApiV1TemplatePreviewPreviewEmailPost({
        requestBody,
    }: {
        requestBody: HTMLTemplatePreviewRequest,
    }): CancelablePromise<HTMLTemplatePreviewResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/template_preview/preview-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Preview Sms
     * Preview an SMS template with given data.
     * @returns SMSTemplatePreviewResponse Successful Response
     * @throws ApiError
     */
    public previewSmsApiV1TemplatePreviewPreviewSmsPost({
        requestBody,
    }: {
        requestBody: SMSMessagePreviewRequest,
    }): CancelablePromise<SMSTemplatePreviewResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/template_preview/preview-sms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Preview Email Base Template
     * Preview a base email template with given brand data and theme.
     * @returns BaseTemplatePreviewResponse Successful Response
     * @throws ApiError
     */
    public previewEmailBaseTemplateApiV1TemplatePreviewPreviewEmailBaseTemplatePost({
        requestBody,
    }: {
        requestBody: BaseTemplatePreviewRequest,
    }): CancelablePromise<BaseTemplatePreviewResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/template_preview/preview-email-base-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
