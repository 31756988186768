import axios from "axios";
import invariant from "ts-invariant";

import * as fetchers from "../../services/fetchers";
import type { CreateAxiosOptions } from "./types";

export async function refreshAuthTokens({
  getAuthContext,
  updateAuthState,
  logout,
}: CreateAxiosOptions) {
  if (localStorage.getItem("refreshingTokens") === "true") {
    // Wait for another tab to finish refreshing the tokens
    // to prevent a race condition and save the user from being logged out,
    // as a refresh token can be used only once.
    //
    // If this flag is set and this function has still been called,
    // it means another tab is refreshing the tokens at the moment.
    // `axios-auth-refresh` makes sure `refreshAuthTokens` isn't called twice
    // for the same axios instance (and we have only one in a tab).
    // So listening to the storage event is enough.
    return new Promise<void>((resolve) => {
      window.addEventListener("storage", (e) => {
        if (e.storageArea !== localStorage) return;
        if (e.key !== "refreshingTokens") return;
        if (e.newValue !== "false") return;

        // We should be ok to just resolve here, as "refreshingTokens" flag
        // gets updated after the auth store. The tab refreshing the tokens
        // updates its auth store, that gets persisted to the local storage,
        // and other tabs listen to that and rehydrate their stores.
        resolve();
      });
    });
  }

  localStorage.setItem("refreshingTokens", "true");

  const markTokensNotBeingRefreshed = () => {
    localStorage.setItem("refreshingTokens", "false");
  };

  // Reset the flag if the user closes the tab
  // while a request to refresh the tokens is in flight.
  window.addEventListener("beforeunload", markTokensNotBeingRefreshed);

  try {
    const authContext = getAuthContext();

    invariant(authContext.msRefreshToken, "Expected a refresh token to be in store");

    // We're using a different (global) axios instance to refresh the tokens,
    // so that request doesn't get stalled by our custom instance.
    // We can use the same instance if we need. For that, `axios-auth-refresh` library
    // supports `skipAuthRefresh: true` option on the request config.
    // Chose the former for simplicity.
    const newTokens = await fetchers.refreshAuthTokens(axios, {
      refreshToken: authContext.msRefreshToken,
    });

    updateAuthState({
      msAccessToken: newTokens.accessToken,
      msRefreshToken: newTokens.refreshToken,
    });
  } catch (e) {
    // Here we're logging the user out if any exception occurs while refreshing the tokens
    // for simplicity; instead, we could retry refreshing and log out only if an unrecoverable
    // exception occurs (such as a 4xx error).
    logout();
  } finally {
    window.removeEventListener("beforeunload", markTokensNotBeingRefreshed);
    markTokensNotBeingRefreshed();
  }
}
