/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTemplateSchemaResponse } from '../models/BaseTemplateSchemaResponse';
import type { CommCode } from '../models/CommCode';
import type { TemplateSchemaResponse } from '../models/TemplateSchemaResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CommSchemaService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Comm Codes
     * Get a list of available comm codes
     * @returns TemplateSchemaResponse Successful Response
     * @throws ApiError
     */
    public commCodesApiV1CommSchemaCodesGet(): CancelablePromise<Array<TemplateSchemaResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/comm_schema/codes',
        });
    }
    /**
     * Template Schema
     * Get the schema for a template
     * @returns TemplateSchemaResponse Successful Response
     * @throws ApiError
     */
    public templateSchemaApiV1CommSchemaSchemaCommCodeGet({
        commCode,
    }: {
        commCode: CommCode,
    }): CancelablePromise<TemplateSchemaResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/comm_schema/schema/{comm_code}',
            path: {
                'comm_code': commCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Base Template Schema
     * Get the default schema for a base template
     * @returns BaseTemplateSchemaResponse Successful Response
     * @throws ApiError
     */
    public baseTemplateSchemaApiV1CommSchemaSchemaBaseTemplateGet(): CancelablePromise<BaseTemplateSchemaResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/comm_schema/schema/base_template',
        });
    }
}
