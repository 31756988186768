import { typeCast } from "./assert";

interface ErrorResponseWithStatus {
  response: {
    status: number;
  };
}

export const isErrorResponseWithStatus = (err: unknown): err is ErrorResponseWithStatus => {
  if (err === null) return false;
  if (typeof err !== "object") return false;

  typeCast<ErrorResponseWithStatus>(err);

  if (typeof err.response !== "object") return false;
  if (typeof err.response.status !== "number") return false;
  return true;
};
