import axios, { type AxiosError, type AxiosHeaders, type AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { commsURI } from "src/services/commsURI";
import { marketplaceAPIURI } from "src/services/marketplaceAPIURI";
import { multiservicesURI } from "src/services/multiservicesURI";
import type { Maybe } from "src/utils/types";

import { refreshAuthTokens } from "./refreshAuthTokens";
import type { AuthContext, CreateAxiosOptions } from "./types";

export function createAxios({ getAuthContext, updateAuthState, logout }: CreateAxiosOptions) {
  const instance = axios.create({
    withCredentials: true,
    xsrfCookieName: "csrftoken",
  });

  instance.interceptors.request.use((config) => {
    const fullUrl = getFullUrl(config.baseURL, config.url);
    const accessToken = getAccessToken(fullUrl, getAuthContext());

    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    };

    if (!config.headers) {
      // TODO: fix this
      config.headers = {} as AxiosHeaders;
    }

    Object.assign(config.headers, headers);

    return config;
  });

  createAuthRefreshInterceptor(
    instance,
    async () => {
      await refreshAuthTokens({ getAuthContext, updateAuthState, logout });
    },
    { shouldRefresh: shouldRefreshAuthTokens },
  );

  return instance;
}

function getFullUrl(baseURL: string | undefined, url: string | undefined): string {
  // if url is absolute, no need to append baseURL
  if (url && !url.startsWith("/")) return url;

  return [baseURL, url].filter(Boolean).join("");
}

function getAccessToken(requestUrl: string, authContext: AuthContext): Maybe<string> {
  if (isRequestToMS(requestUrl) || isRequestToComms(requestUrl)) {
    return authContext.msAccessToken;
  }

  if (requestUrl.startsWith(marketplaceAPIURI)) {
    return authContext.marketplaceApiAccessToken;
  }
}

function isRequestToMS(requestUrl: string) {
  return requestUrl.startsWith(multiservicesURI);
}

function isRequestToComms(requestUrl: string) {
  return requestUrl.startsWith(commsURI);
}

function shouldRefreshAuthTokens(error: AxiosError): boolean {
  if (error.response?.status !== 401) return false;
  if (!error.config || !isAuthenticatedRequest(error.config)) return false;

  const fullUrl = getFullUrl(error.config?.baseURL, error.config?.url);

  return isRequestToMS(fullUrl) || isRequestToComms(fullUrl);
}

function isAuthenticatedRequest(config: AxiosRequestConfig): boolean {
  const headers = config.headers;

  if (!headers) return false;
  if (!("Authorization" in headers)) return false;
  return Boolean(headers.Authorization);
}
