import { commsClient, commsClientAdapter } from "./commsClient";
import {
  getAppointment,
  getAppointments,
  getAvailability,
  getCompany,
  getContact,
  getEntityTags,
  getEntityTagsCount,
  getInvoice,
  getInvoiceDetailsForJob,
  getInvoices,
  getJob,
  getJobEvents,
  getJobs,
  getJobsheet,
  getJobsheetReport,
  getManagedRates,
  getPartner,
  getPartnerDomains,
  getPartners,
  getProduct,
  getProducts,
  getQuote,
  getTags,
  getTradespersonPartners,
  getTradespersonPartner,
  getTradespersonByPartnerTradesperson,
  getTradespersonCompany,
  getTradespersonEvents,
  getTradespersonCompanyEvents,
  listCompanies,
  listCompanyPartners,
  listTradespersonCompanies,
  listPermissionGroups,
} from "./fetchers";
import { marketplaceAPIURI } from "./marketplaceAPIURI";
import type { Fetch, QueryOptions } from "./useQuery";
import { useQuery } from "./useQuery";

export const useJobs = createQueryHook("jobs", getJobs);
export const useJob = createQueryHook("job", getJob);

export const useJobsheet = createQueryHook("jobsheet", getJobsheet);
export const useJobsheetReport = createQueryHook("jobsheetReport", getJobsheetReport);

export const useJobEvents = createQueryHook("jobEvents", getJobEvents);

export const useInvoices = createQueryHook("invoices", getInvoices);
export const useInvoice = createQueryHook("invoice", getInvoice);
export const useInvoiceDetailsForJob = createQueryHook(
  "invoiceDetailsForJob",
  getInvoiceDetailsForJob,
);
export const useQuote = createQueryHook("quote", getQuote);

export const useTradespersonPartners = createQueryHook(
  "tradespersonPartners",
  getTradespersonPartners,
);
export const useTradespersonPartner = createQueryHook(
  "tradespersonPartner",
  getTradespersonPartner,
);

/**
 * Get a tradesperson partner (TTP) via a partner id and a tradesperson id (TP)
 */
export const useTradespersonByPartnerTradesperson = createQueryHook(
  "tradespersonByPartnerTradesperson",
  getTradespersonByPartnerTradesperson,
);

export const useTradespersonEvents = createQueryHook("tradespersonEvents", getTradespersonEvents);
export const useTradespersonCompanyEvents = createQueryHook(
  "tradespersonCompanyEvents",
  getTradespersonCompanyEvents,
);

export const useAppointments = createQueryHook("appointments", getAppointments);
export const useAppointment = createQueryHook("appointment", getAppointment);
export const useAvailability = createQueryHook("availability", getAvailability);

export const useContact = createQueryHook("contact", getContact);

export const useManagedRates = createQueryHook("managedRates", getManagedRates);
export const usePartnerDomains = createQueryHook("partnerDomains", getPartnerDomains);

export const useTags = createQueryHook("tags", getTags);
export const useEntityTagsCount = createQueryHook("entityTagsCount", getEntityTagsCount);
export const useEntityTags = createQueryHook("entityTags", getEntityTags);

export const usePartner = createQueryHook("partner", getPartner);
export const usePartners = createQueryHook("partners", getPartners);
export const useProducts = createQueryHook("products", getProducts);
export const useProduct = createQueryHook("product", getProduct);

export const useCheckHealth = createQueryHook("checkHealth", async (axios) => {
  return await axios.get(`${marketplaceAPIURI}/api/health`);
});

export const useCommTemplate = createQueryHook(
  "commTemplate",
  commsClientAdapter(
    commsClient.templates.getTemplateApiV1TemplatesIdGet.bind(commsClient.templates),
  ),
);

export const useListCommTemplates = createQueryHook(
  "commTemplates",
  commsClientAdapter(
    commsClient.templates.listTemplateApiV1TemplatesGet.bind(commsClient.templates),
  ),
);

export const useDefaultCommTemplate = createQueryHook(
  "defaultCommTemplate",
  commsClientAdapter(
    commsClient.templates.getDefaultTemplateApiV1TemplatesDefaultGet.bind(commsClient.templates),
  ),
);

export const useBaseCommTemplates = createQueryHook(
  "baseCommTemplates",
  commsClientAdapter(
    commsClient.baseTemplates.listTemplateApiV1BaseTemplatesGet.bind(commsClient.templates),
  ),
);

export const useBaseCommTemplate = createQueryHook(
  "baseCommTemplate",
  commsClientAdapter(
    commsClient.baseTemplates.getBaseTemplateApiV1BaseTemplatesIdGet.bind(commsClient.templates),
  ),
);

export const useDefaultBaseCommTemplate = createQueryHook(
  "defaultBaseCommTemplate",
  commsClientAdapter(
    commsClient.baseTemplates.getDefaultBaseTemplateApiV1BaseTemplatesDefaultGet.bind(
      commsClient.baseTemplates,
    ),
  ),
);

export const useCommSchema = createQueryHook(
  "commSchema",
  commsClientAdapter(
    commsClient.commSchema.templateSchemaApiV1CommSchemaSchemaCommCodeGet.bind(
      commsClient.commSchema,
    ),
  ),
);

export const useCommCodes = createQueryHook(
  "commSchema",
  commsClientAdapter(
    commsClient.commSchema.commCodesApiV1CommSchemaCodesGet.bind(commsClient.commSchema),
  ),
);

export const useBaseCommSchema = createQueryHook(
  "baseCommSchema",
  commsClientAdapter(
    commsClient.commSchema.baseTemplateSchemaApiV1CommSchemaSchemaBaseTemplateGet.bind(
      commsClient.commSchema,
    ),
  ),
);

export const useBrandTemplateConfig = createQueryHook(
  "brandTemplateConfig",
  commsClientAdapter(
    commsClient.brandConfig.getBrandConfigApiV1BrandConfigIdGet.bind(commsClient.brandConfig),
  ),
);

export const useCompany = createQueryHook("company", getCompany);
export const useCompanies = createQueryHook("companies", listCompanies);

export const useCompanyPartners = createQueryHook("companyPartners", listCompanyPartners);

export const useTradespersonCompany = createQueryHook(
  "tradespersonCompany",
  getTradespersonCompany,
);

export const useTradespersonCompanies = createQueryHook(
  "tradespersonCompanies",
  listTradespersonCompanies,
);

export const usePermissionGroups = createQueryHook("permissionGroups", listPermissionGroups);

type BoundQueryOptions<TVariables, TData> = Omit<QueryOptions<TVariables, TData>, "name" | "fetch">;

/**
 * Creates a custom query hook bound to a specific fetch function and query name.
 *
 * @param {string} name - The base name for the query.
 * @param {Fetch<TVariables, TData>} fetch - The function for fetching data.
 * @returns {function} A custom hook to perform the query with the provided options.
 *
 * @template TVariables - The type of variables for the query.
 * @template TData - The type of data returned by the query.
 *
 * useBoundQuery:
 * @param {BoundQueryOptions<TVariables, TData>} options - The options for configuring the query.
 * @param {string} [optionalQueryName] - An optional suffix for the query name.
 * @returns {QueryResult<TData>} The result of the query, including data and query state.
 */
function createQueryHook<TVariables, TData>(name: string, fetch: Fetch<TVariables, TData>) {
  function useBoundQuery(options: BoundQueryOptions<TVariables, TData>) {
    const query = useQuery({ name, fetch, ...options });

    return query;
  }

  return useBoundQuery;
}
