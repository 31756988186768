import type { AxiosInstance } from "axios";
import { useCallback } from "react";
import type { UseQueryOptions } from "react-query";
import { useQueryClient } from "react-query";
// eslint-disable-next-line  @typescript-eslint/no-restricted-imports
import { useQuery as useBaseQuery } from "react-query";

import { logoutOn403 } from "src/utils/logoutOn403";

import { useAxios } from "../context/axios";

export type Fetch<TVariables, TData> = (
  axios: AxiosInstance,
  variables: TVariables,
) => Promise<TData>;

type OverrideableBaseQueryOptions<TVariables, TData> = Omit<
  UseQueryOptions<TData, unknown, TData, [string, TVariables]>,
  "queryKey" | "queryFn" | "enabled"
>;

export interface QueryOptions<TVariables, TData>
  extends OverrideableBaseQueryOptions<TVariables, TData> {
  name: string;
  variables: TVariables;
  fetch: Fetch<TVariables, TData>;
  skip?: boolean;
  extraKeys?: unknown[];
}

export const useQuery = <TVariables, TData>({
  name,
  variables,
  fetch,
  skip,
  extraKeys = [],
  ...useQueryOptions
}: QueryOptions<TVariables, TData>) => {
  const axios = useAxios();
  const client = useQueryClient();
  const queryKey = [name, variables, ...extraKeys] as [string, TVariables];

  const query = useBaseQuery({
    queryKey,
    queryFn: async () => fetch(axios, variables),
    enabled: !skip,
    onError: logoutOn403,
    ...useQueryOptions,
  });

  const updateData = (updater: TData | ((data?: TData) => TData)) => {
    client.setQueryData(queryKey, updater);
  };

  return { ...query, queryKey, updateData };
};

export function useFetcher<T, U>(fn: (axios: AxiosInstance, params: T) => U): (params: T) => U {
  const axios = useAxios();

  return useCallback((params: T) => fn(axios, params), [axios, fn]);
}
