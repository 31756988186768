import { z } from "zod";

const urlSchema = z.string().url();

export function validateUrlString(url: string | undefined | null, errorMsg: string): string {
  const maybeUrl = urlSchema.safeParse(url);

  if (!maybeUrl.success) {
    throw new Error(`Error: ${errorMsg} - value provided: ${url}`);
  }

  return maybeUrl.data;
}
