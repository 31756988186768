/**
 * Type assertion without runtime check - useful in certain contexts like type predicate
 * functions or other cases where its useful to assert a specific type within a branch
 * and its safe to do so
 *
 * **Use with care**
 *
 * @example
 * ```
 * declare const a: string | number;
 * a.toLowerCase(); // tsc error
 * typeCast<string>(a);
 * a.toLowerCase(); // ok
 * ```
 */
export function typeCast<T>(x: unknown): asserts x is T {}

/**
 * Assert
 * @throws
 *
 * @example
 * ```
 * declare const a: string | number;
 * a.toLowerCase(); // tsc error
 * assert(typeof a === "string", "expecting string");
 * a.toLowerCase(); // ok
 * ```
 */
export function assert(condition: boolean, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
