/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandResponse } from '../models/BrandResponse';
import type { CreateBrandRequest } from '../models/CreateBrandRequest';
import type { UpdateBrandRequest } from '../models/UpdateBrandRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BrandConfigService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Brand Config
     * Get the brand configuration for a given brand ID.
     * @returns BrandResponse Successful Response
     * @throws ApiError
     */
    public getBrandConfigApiV1BrandConfigIdGet({
        id,
    }: {
        id: string,
    }): CancelablePromise<BrandResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/brand_config/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Brand Config
     * Create a new brand configuration for a given brand ID.
     * @returns BrandResponse Successful Response
     * @throws ApiError
     */
    public createBrandConfigApiV1BrandConfigIdPost({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CreateBrandRequest,
    }): CancelablePromise<BrandResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/brand_config/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Brand Config
     * Update a brand configuration for a given brand ID.
     * @returns BrandResponse Successful Response
     * @throws ApiError
     */
    public updateBrandConfigApiV1BrandConfigIdPatch({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateBrandRequest,
    }): CancelablePromise<BrandResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/brand_config/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
