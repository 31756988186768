import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuthStore } from "src/context/authStore";
import { useCheckHealth } from "src/services/queries";

interface RouteGuardProps {
  children: React.ReactNode;
}

export function RouteGuard({ children }: RouteGuardProps) {
  const router = useRouter();
  const checkHealthQuery = useCheckHealth({ variables: {} });

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useEffect(() => {
    if (checkHealthQuery.isLoading) {
      return;
    }

    if (!isAuthenticated && router.asPath !== "/login") {
      router.push("/login");
    }
  }, [checkHealthQuery.isLoading, isAuthenticated, router]);

  if (checkHealthQuery.isLoading) {
    return null;
  }

  return <>{children}</>;
}
