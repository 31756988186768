interface StoreWithPersist {
  persist: {
    getOptions: () => { name?: string };
    rehydrate: () => void;
  };
}

export const rehydrateOnDOMStorageEvent = (store: StoreWithPersist) => {
  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name) {
      store.persist.rehydrate();
    }
  };

  if (typeof window === "undefined") return;

  window.addEventListener("storage", storageEventCallback);

  return () => {
    window.removeEventListener("storage", storageEventCallback);
  };
};
