import { createContext, useContext } from "react";

/**
 * Create a context where the consumer hook doesn't have to check if the context value is fulfilled.
 * Enforces the consumer to be wrapped with a provider and the provider to pass a value.
 */
export const createSafeContext = <T>(name: string) => {
  const context = createContext<T | null>(null);

  const useContextValue = () => {
    const maybeValue = useContext(context);

    if (!maybeValue) {
      throw new Error(
        `No ${name} provider in scope. Provide a ${name} provider in a parent component.`,
      );
    }

    return maybeValue;
  };

  return [context, useContextValue] as const;
};
