import { logout } from "./auth";
import { isErrorResponseWithStatus } from "./isErrorResponseWithStatus";

export const logoutOn403 = (err: unknown) => {
  if (isErrorResponseWithStatus(err)) {
    if (err.response.status === 403) {
      logout();
    }
  }
};
