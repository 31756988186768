import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { dummyStorageApi } from "src/utils/dummyStorageApi";
import { rehydrateOnDOMStorageEvent } from "src/utils/rehydrateOnDOMStorageEvent";

export interface AuthStore {
  username: string;
  isAuthenticated: boolean;
  isAdmin: boolean;
  accessToken: string | null;
  msAccessToken: string | null;
  msRefreshToken: string | null;
  partnerId: string | null;
  permissions: string[];
}

const defaultStore: Readonly<AuthStore> = {
  username: "",
  isAuthenticated: false,
  isAdmin: false,
  accessToken: null,
  msAccessToken: null,
  msRefreshToken: null,
  partnerId: null,
  permissions: [],
};

export const useAuthStore = create(
  persist<AuthStore & { reset: () => void }>(
    (set) => {
      return {
        reset: () => set(defaultStore),
        ...defaultStore,
      };
    },
    {
      name: "auth-store",
      version: 1,
      storage: createJSONStorage(() =>
        typeof window !== "undefined" ? window.localStorage : dummyStorageApi,
      ),
    },
  ),
);

rehydrateOnDOMStorageEvent(useAuthStore);
