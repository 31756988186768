import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import type { EmotionCache } from "@emotion/react";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enGB from "date-fns/locale/en-GB";
import type { AppProps } from "next/app";
import Head from "next/head";
import React, { useMemo } from "react";
import { QueryClientProvider } from "react-query";

import { RouteGuard } from "src/components/RouteGuard";
import { AxiosProvider } from "src/context/axios";
import { createQueryClient } from "src/services/createQueryClient";
import { ConfigProvider as BerryConfigProvider } from "src/vendor/berry/contexts/ConfigContext";
import BerryThemeCustomization from "src/vendor/berry/themes";

import { createEmotionCache } from "../utils/create-emotion-cache";

const clientSideEmotionCache = createEmotionCache();

interface LayoutHolder {
  getLayout?: (page: React.ReactNode) => React.ReactNode;
}

// No real idea what page props is meant to be other than not null
// eslint-disable-next-line @typescript-eslint/ban-types
interface CustomAppProps extends AppProps<{}> {
  emotionCache: EmotionCache;
}

const App = (props: CustomAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const queryClient = useMemo(() => createQueryClient(), []);

  const getLayout = (Component as LayoutHolder).getLayout ?? ((page: React.ReactNode) => page);

  process.on("unhandledRejection", (err, promise) => {
    console.log(`Unhandled rejection (promise: `, promise, `, reason: `, err, `)`);
  });

  return (
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryClient}>
        <BerryConfigProvider>
          <BerryThemeCustomization>
            <Head>
              <title>Kantan</title>
              <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <CssBaseline />
              <AxiosProvider>
                <RouteGuard>{getLayout(<Component {...pageProps} />)}</RouteGuard>
              </AxiosProvider>
            </LocalizationProvider>
          </BerryThemeCustomization>
        </BerryConfigProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
};

export default App;
