import { QueryClient, setLogger } from "react-query";

export const createQueryClient = () => {
  // mutes the rather noisy and uncessary logs when network requests fail
  setLogger({ log: () => {}, error: () => {}, warn: () => {} });

  return new QueryClient({
    defaultOptions: {
      queries: {
        // The options matching the "cache-and-network" fetch policy
        // i.e. when mounting a query, return data from cache yet refetch in background
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        // Note: `true` refetches on mount only if data is considered stale
        refetchOnMount: true,
        staleTime: 0,
        cacheTime: Infinity,

        // Defaults to 3 times. It doesn't seem needed to refetch
        // more than once if the query has failed.
        // We can pass a predicate to skip retries completely
        // if we believe we can't recover from the error.
        retry: 1,
      },
    },
  });
};
