/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommCode } from '../models/CommCode';
import type { CreateHTMLTemplateRequest } from '../models/CreateHTMLTemplateRequest';
import type { CreateSMSTemplateRequest } from '../models/CreateSMSTemplateRequest';
import type { HTMLTemplateResponse } from '../models/HTMLTemplateResponse';
import type { LiveForProductRequest } from '../models/LiveForProductRequest';
import type { SendTestEmailConfig } from '../models/SendTestEmailConfig';
import type { SMSTemplateResponse } from '../models/SMSTemplateResponse';
import type { Transport } from '../models/Transport';
import type { UpdateTemplateRequest } from '../models/UpdateTemplateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TemplatesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Default Template
     * Get a default template
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDefaultTemplateApiV1TemplatesDefaultGet({
        commCode,
        transport,
    }: {
        commCode: CommCode,
        transport: Transport,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/templates/default',
            query: {
                'comm_code': commCode,
                'transport': transport,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Template
     * Get a template by ID
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTemplateApiV1TemplatesIdGet({
        id,
    }: {
        id: string,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Template
     * Delete a template
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteTemplateApiV1TemplatesIdDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Template
     * Update a template
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateTemplateApiV1TemplatesIdPatch({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateTemplateRequest,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Template
     * List templates
     * @returns any Successful Response
     * @throws ApiError
     */
    public listTemplateApiV1TemplatesGet({
        partnerIds,
        brandIds,
        commCodes,
        transports,
        limit,
        offset,
        liveForPartnerIds,
        liveForBrandIds,
        liveForProductIds,
    }: {
        partnerIds?: Array<string>,
        brandIds?: Array<string>,
        commCodes?: Array<CommCode>,
        transports?: Array<Transport>,
        limit?: number,
        offset?: number,
        liveForPartnerIds?: Array<string>,
        liveForBrandIds?: Array<string>,
        liveForProductIds?: Array<string>,
    }): CancelablePromise<Array<(HTMLTemplateResponse | SMSTemplateResponse)>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/templates/',
            query: {
                'partner_ids': partnerIds,
                'brand_ids': brandIds,
                'comm_codes': commCodes,
                'transports': transports,
                'limit': limit,
                'offset': offset,
                'live_for_partner_ids': liveForPartnerIds,
                'live_for_brand_ids': liveForBrandIds,
                'live_for_product_ids': liveForProductIds,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Template
     * Create a new template
     * @returns any Successful Response
     * @throws ApiError
     */
    public createTemplateApiV1TemplatesPost({
        requestBody,
    }: {
        requestBody: (CreateHTMLTemplateRequest | CreateSMSTemplateRequest),
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/templates/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Base Template
     * Remove a base template from a template - will not delete the base template
     * @returns any Successful Response
     * @throws ApiError
     */
    public removeBaseTemplateApiV1TemplatesIdRemoveBaseTemplatePost({
        id,
    }: {
        id: string,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/templates/{id}/remove-base-template',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Live For Brand
     * Mark a template as live for a brand
     * @returns any Successful Response
     * @throws ApiError
     */
    public liveForBrandApiV1TemplatesIdLiveForBrandPost({
        id,
        live,
    }: {
        id: string,
        live: boolean,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/templates/{id}/live-for-brand',
            path: {
                'id': id,
            },
            query: {
                'live': live,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Live For Product
     * Mark a template as live for products
     * @returns any Successful Response
     * @throws ApiError
     */
    public liveForProductApiV1TemplatesIdLiveForProductsPost({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: LiveForProductRequest,
    }): CancelablePromise<(HTMLTemplateResponse | SMSTemplateResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/templates/{id}/live-for-products',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Test Email
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendTestEmailApiV1TemplatesIdSendTestEmailPost({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: SendTestEmailConfig,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/templates/{id}/send-test-email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
