import { type AxiosInstance } from "axios";
import React, { useState } from "react";
import { create } from "zustand";
import { createSafeContext } from "../createSafeContext";

import { useCreateAxios } from "./useCreateAxios";

const [AxiosContext] = createSafeContext<AxiosInstance>("axios");

export const axiosStore = create<{ axios: AxiosInstance | null }>((set) => ({
  axios: null,
  setAxios: (axios: AxiosInstance) => set({ axios }),
}));

function useAxios() {
  const axios = axiosStore((state) => state.axios);
  return axios as AxiosInstance;
}

export { useAxios };

interface AxiosProviderProps {
  children: React.ReactNode;
}

export const AxiosProvider = ({ children }: AxiosProviderProps) => {
  const axios = useCreateAxios();
  useState(() => {
    axiosStore.setState({ axios });
  });

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};
