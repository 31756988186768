import type { CancelablePromise } from "./comms-client";
import { BaseHttpRequest, CommsClient } from "./comms-client";
import type { ApiRequestOptions } from "./comms-client/core/ApiRequestOptions";
import { request as __request } from "./comms-client/core/request";
import { commsURI } from "./commsURI";
import type { Fetch } from "./useQuery";
import { axiosStore } from "src/context/axios";

export class AxiosHttpRequest extends BaseHttpRequest {
  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return __request(this.config, options, axiosStore.getState().axios!);
  }
}

export const commsClient = new CommsClient({ BASE: commsURI }, AxiosHttpRequest);

export function commsClientAdapter<TVariables, TData>(
  fn: (arg: TVariables) => Promise<TData>,
): Fetch<TVariables, TData> {
  return (axios, args) => {
    return fn(args);
  };
}
