/**
 * __isNotNullOrUndefined__
 *
 * Allows filtering out items that are null or undefined.
 * Narrows the type of each item so you don't need to handle it elsewhere.
 *
 */
export const isNotNullish = <T>(maybeT: T | null | undefined): maybeT is T => {
  return maybeT !== null && maybeT !== undefined;
};
